<template>
    <v-layout
        mt-5
        justify-center>
        <v-flex
            v-if="!isLoggedIn"
            xs12
            sm10
            md8
            lg5
            pt-5
            mt-5>
            <v-card class="wrap-content gray-icons">
                <v-alert
                    :value="userMessage"
                    type="error"
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    {{ userMessage }}
                </v-alert>
                <v-alert
                    :value="error"
                    type="error"
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    <span v-html="error" />
                </v-alert>
                <v-toolbar
                    color="white"
                    class="first-line-toolbar">
                    <v-toolbar-title class="light-blue--text title-block">
                        Login
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            id="login-form"
                            v-model="valid"
                            class="text-xs-center"
                            @submit.prevent="login">
                            <v-text-field
                                v-model="email"
                                label="E-mail"
                                :rules="emailRules"
                                required
                                class="py-2 px-5" />
                            <v-text-field
                                v-model="password"
                                label="Password"
                                class="pt-4 pb-0 px-5"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="passwordRules"
                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                required
                                light
                                @click:append="showPassword = !showPassword" />
                            <div class="text-xs-right px-5 mb-3">
                                <router-link
                                    class="text-xs-right pb-5"
                                    style="text-decoration: none;"
                                    to="forgot-password">
                                    Forgot Password?
                                </router-link>
                            </div>

                            <div class="form-button">
                                <v-btn
                                    flat
                                    large
                                    type="submit"
                                    form="login-form"
                                    class="blue-btn"
                                    color="white"
                                    :loading="loading"
                                    :disabled="!valid">
                                    Login
                                </v-btn>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { handleTwoFa } from '@/helpers/handleTwoFa';
import { TRUSTED_DEVICE_TOKEN } from '@/helpers/storageKeys';

export default {
    title: 'Login',
    data() {
        return {
            showPassword: false,
            loading: false,
            error: false,
            valid: false,
            password: '',
            email: '',
            token: null,
            passwordRules: [
                (v) => !!v || 'Password is required',
            ],
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'E-mail must be valid'
            ]
        };
    },
    computed: {
        ...mapGetters([
            'userHomepage'
        ]),
        ...mapState({
            isLoggedIn: (state) => state.user.isLoggedIn
        }),
        userMessage() {
            if (!this.$route.query.user_message) {
                return false;
            }

            switch (this.$route.query.user_message) {
                case 'session_timeout':
                    return 'Your session has timed out.  Please login again.';
                default:
                    return false;
            }
        }
    },
    mounted() {
        this.getEmail();

        if (this.isLoggedIn) {
            this.$router.push(this.userHomepage);
        }
    },
    methods: {
        async login() {
            try {
                this.loading = true;
                this.error = false;

                const config = {
                    email: this.email,
                    password: this.password,
                    init: true,
                    trustedDeviceToken: localStorage.getItem(TRUSTED_DEVICE_TOKEN + this.email)
                };

                const result = await this.$store.dispatch('login', config);

                if (result.status === 'reset_password_required') {
                    this.$router.push({ name: 'forgot-password', params: { email: this.email, enforcedReset: true } });
                }

                await handleTwoFa(result.status, this.$router, this.$apiRepository, this.email, this.password);
            } catch(e) {
                let message = 'An error occurred.  Please try again or <a href="mailto:support@buyerbridge.io">contact support</a>.';

                if (get(e, 'error') === 'invalid_grant' || e.response?.data?.error === 'Invalid credentials') {
                    message = 'The username and password you entered do not match. Please try again.';
                }

                this.error = message;

            } finally {
                this.loading = false;
            }
        },
        getEmail() {
            if (this.$route.query.email !== undefined) {
                this.email = this.$route.query.email;
            }
        }
    }
};
</script>
